import { Container, styled } from '@material-ui/core';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import react, { useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 18,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F4F4F4',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: '#22CAE0',
  },
}));

type Props = {
  value: number;
  color: string;
  label: string;
  buttonText: string;
  infoValue: string;
  infoUnit: string;
  infoLabel: string;
};

const ActivityProgress = (props: Props): React.ReactElement => {
  const { color, label, buttonText, value, infoValue, infoLabel, infoUnit } = props;
  const [collapse, setCollapse] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
        <Box sx={{ width: 150, position: 'relative' }}>
          <BorderLinearProgress
            value={progress}
            variant={'determinate'}
            sx={{
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: `${color} !important`,
              },
            }}
          />
          <Box sx={{ position: 'absolute', top: '50%', right: 10, transform: 'translateY(-50%)' }}>
            <Typography sx={{ color: '#000', fontSize: 12 }}>{label}</Typography>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Button
            // endIcon={collapse ? <ExpandLess fontSize={'small'} /> : <ExpandMore fontSize={'small'} />}
            onClick={() => setCollapse(!collapse)}
            sx={{
              color: '#000',
              fontSize: 12,
              marginLeft: '10px',
              textTransform: 'none',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
      {/* <Collapse in={collapse} timeout="auto" unmountOnExit>
        <Box>
          <Typography sx={{ fontSize: '22px', fontWeight: 'bold', color: color }}>
            {infoValue}
            <Typography sx={{ fontSize: '9px', color: 'rgba(0,0,0,0.54)' }} component={'span'}>
              {infoUnit}
            </Typography>{' '}
          </Typography>
          <Typography sx={{ fontSize: '11px' }}>{infoLabel}</Typography>
        </Box>
      </Collapse> */}
    </>
  );
};

export default ActivityProgress;
