import { Alert, Box, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PDTButton from './Buttons/PDTButton';
import { firebaseApp } from '..';
import { Link } from 'react-router-dom';
import ApplicationPaths from '../utils/paths';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

const ForgotPasswordForm = () => {
  const [state, setState] = useState({
    success: false,
    loading: false,
    buttonText: 'Forgot Password',
    error: '',
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //TODO: update action code settings
      try {
        setState({
          ...state,
          loading: true,
          buttonText: 'Sending...',
        });
        await firebaseApp.auth().sendPasswordResetEmail(values.email, {
          url: `${process.env.REACT_APP_URL}${ApplicationPaths.RESET_CONTINUE}?email=${values.email}`,
        });
        setState({
          ...state,
          success: true,
          loading: false,
          buttonText: 'Forgot Password',
        });
        formik.resetForm();
      } catch (error) {
        setState({
          ...state,
          loading: false,
          success: false,
          error: 'Failed to send password reset email',
          buttonText: 'Forgot Password',
        });
      }
    },
  });

  return (
    <>
      <Grid container maxWidth="sm" rowSpacing={1} columnSpacing={0} justifyContent={'center'}>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {state.error && (
            <Alert sx={{ my: 2 }} severity="error">
              {state.error}
            </Alert>
          )}
          {state.success && (
            <Alert sx={{ my: 2 }} severity="success">
              Password reset link sent
            </Alert>
          )}
        </Grid>
        <Grid item xs={6} md={6} sx={{ textAlign: 'center' }}>
          <PDTButton
            variant="contained"
            fullWidth
            disabled={state.loading}
            onClick={() => {
              formik.submitForm();
            }}
            sx={{ mt: 3 }}
          >
            {state.buttonText}
          </PDTButton>
          <Box>
            <p style={{ marginTop: 15, textAlign: 'center' }}>
              <Link to={ApplicationPaths.LOGIN} style={{ color: '#06719b' }}>
                Login
              </Link>
            </p>
          </Box>
        </Grid>
      </Grid>
      <Box></Box>
    </>
  );
};

export default ForgotPasswordForm;
