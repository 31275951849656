import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ApplicationPaths from '../../utils/paths';
import ActivityChart, { ActivityChartProps } from './ActivityChart';

type Stat = {
  stat: string;
  unit: string;
  description: string;
};

interface Props extends ActivityChartProps {
  stats: Stat[];
}

//https://recharts.org/en-US/examples/PieChartWithPaddingAngle for chart

const Activity = ({ stats, color, info, data }: Props): JSX.Element => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '18px',
          color: 'rgba(0,0,0,0.54)',
        }}
      >
        My Activity
      </Typography>
      <Box
        component="div"
        sx={{
          height: '1px',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.2)',
        }}
      ></Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          <ActivityChart color={color} data={data} info={info} />
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '11px',
              '& a': {
                display: 'inline-block',
                color: '#06719b',
                marginBottom: '15px',
              },
            }}
          >
            <Link to={ApplicationPaths.FUNDS_BREAKDOWN}>See Full Breakdown</Link>
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {stats.map(({ stat, unit, description }, index) => (
            <Box key={index} sx={{ textAlign: 'center', marginBottom: '10px' }}>
              <Typography sx={{ color: '#06719b', fontSize: '20px', fontWeight: 'bold' }}>
                {stat}
                <Typography component={'span'} sx={{ fontSize: '9px', color: 'rgba(0,0,0,0.5)' }}>
                  {unit}
                </Typography>
              </Typography>
              <Typography sx={{ fontSize: '10px', color: 'rbga(0,0,0,0.73)' }}>{description}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Activity;
