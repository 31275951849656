import { IonContent, IonPage } from '@ionic/react';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import ContributorList from '../components/VolunteerDashboard/ContributorList';
import NavigationTabBar from '../components/NavigationTabBar';
import SignupContributorButton from '../components/Buttons/SignupContributorButton';
import ContributorListHeader from '../components/VolunteerDashboard/ContributorListHeader';
import SplitLayout from '../layouts/SplitLayout';
import APIEndpoints from '../utils/endpoints';
import pdtClient from '../lib/api';

export type Contributor = {
  id: number;
  name: string;
  donations_total_to_date: string;
  activeSubscription: boolean;
};

const ContributorListPage = (): JSX.Element => {
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [totalContributors, setTotalContributors] = useState(0);
  const [activeContributors, setActiveContributors] = useState(0);
  const [loading, setLoading] = useState(true);

  const getContributors = async () => {
    try {
      setLoading(true);
      const response = await pdtClient.get(APIEndpoints.GetContributors);
      const { contributors, totalContributors, activeContributors } = response.data.data;
      setContributors(contributors);
      setTotalContributors(totalContributors);
      setActiveContributors(activeContributors);
    } catch (e) {
      console.log('Error Occurred while fetching subscribers');
      throw e;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContributors();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <SplitLayout
          header={
            <ContributorListHeader totalContributors={totalContributors} activeContributors={activeContributors} />
          }
          component={<ContributorList contributors={contributors} loading={loading} />}
        >
          <Container>
            <SignupContributorButton />
          </Container>
        </SplitLayout>
      </IonContent>
      <NavigationTabBar />
    </IonPage>
  );
};

export default ContributorListPage;
