import { IonContent, IonPage } from '@ionic/react';
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import NavigationTabBar from '../components/NavigationTabBar';
import AppContext from '../context/appContext';
import SplitLayout from '../layouts/SplitLayout';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import ApplicationPaths from '../utils/paths';

const RedeemPage = () => {
  const { me, updateMe } = useContext(AppContext);
  const [state, setState] = useState({
    loading: false,
    buttonText: 'Redeem Now',
    error: '',
    success: false,
  });
  const [rewards, setRewards] = useState('$0.00');

  useEffect(() => {
    const getTravelPoints = async () => {
      try {
        const response = await pdtClient.get(APIEndpoints.UserImpact);
        const { travelPoints } = response.data.data;
        setRewards(travelPoints);
      } catch (error) {
        throw new Error('An error occurred');
      }
    };
    getTravelPoints();
  }, []);

  const handleClick = async () => {
    try {
      setState({ ...state, loading: true, buttonText: 'Redeeming...' });
      await pdtClient.post(ApplicationPaths.REDEEM);

      //Keep button disabled with loading true
      setState({ ...state, loading: true, buttonText: 'Redeem Now', success: true });

      if (me) {
        updateMe({ ...me, redeem_requested: true });
      }
    } catch (error) {
      setState({ ...state, loading: false, buttonText: 'Redeem Now', error: 'An error occurred, try again' });
    }
  };

  return (
    <>
      <IonPage>
        <IonContent>
          <SplitLayout
            header={
              <>
                <Container>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Redeem Travel Rewards
                    </Typography>
                  </Box>
                </Container>
              </>
            }
            component={
              <>
                <Box sx={{ m: 3 }}>
                  <Typography variant="h5">How it works</Typography>
                  <Typography>
                    To access your rewards, please click the “Redeem Travel Rewards” button and a member of our team
                    will contact you with next steps.
                  </Typography>
                </Box>
              </>
            }
          >
            {!state.success && me?.redeem_requested ? (
              <Grid item xs={12}>
                <Alert sx={{ my: 2 }} severity="info">
                  Redeem already requested
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  {state.error && (
                    <Alert sx={{ my: 2 }} severity="error">
                      {state.error}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {state.success && (
                    <Alert sx={{ my: 2 }} severity="success">
                      Redeem requested
                    </Alert>
                  )}
                </Grid>
                {rewards === '$0.00' ? (
                  <Alert severity="info">Insufficient rewards: {rewards}</Alert>
                ) : (
                  <Button
                    sx={{
                      mb: 4,
                      backgroundColor: '#06719b',
                      color: '#fff',
                      boxShadow: 'unset',
                      borderRadius: '22px',
                      textTransform: 'unset',
                      '&:hover': {
                        backgroundColor: '#06719b',
                        boxShadow: 'unset',
                        color: '#fff',
                      },
                    }}
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={state.loading}
                    onClick={handleClick}
                  >
                    {state.buttonText}
                  </Button>
                )}
              </>
            )}
          </SplitLayout>
        </IonContent>
        <NavigationTabBar />
      </IonPage>
    </>
  );
};

export default RedeemPage;
