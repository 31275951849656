import { useEffect, useState } from 'react';
import Activities from '../components/Activity/Activities';
import { Typography, Box } from '@mui/material';
import ActivitiesChart from '../components/Activity/ActivitiesChart';
import { IonContent, IonPage } from '@ionic/react';
import NavigationTabBar from '../components/NavigationTabBar';
import SplitLayout from '../layouts/SplitLayout';
import SignupContributorButton from '../components/Buttons/SignupContributorButton';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import { dollarToNumber } from '../utils/dollarToNumber';
import { BUCKET_COLORS } from '../utils/bucketColors';

type Breakdown = {
  name: string;
  amount: string;
  color: string;
};

const FundsBreakdownPage = (): JSX.Element => {
  const [total, setTotal] = useState('$0.00');
  const [breakdown, setBreakdown] = useState<Breakdown[]>([]);
  const [loading, setLoading] = useState(true);

  const getBreakdown = async () => {
    try {
      setLoading(true);
      const response = await pdtClient.get(APIEndpoints.BreakdownStats);
      const { data } = response.data;
      console.log(data);
      setTotal(data.totalContributions);
      setBreakdown(data.breakdown);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createChartData = (total: string, breakdown: Breakdown[]) => {
    const totalValue = dollarToNumber(total);
    return breakdown.map((x) => {
      let percent = (dollarToNumber(x.amount) / totalValue) * 100;

      //Make everything look round in data
      if (percent < 3 && percent < 2) {
        percent = 3;
      }

      return { name: x.name, value: percent, color: BUCKET_COLORS[x.name] };
    });
  };

  const getBreakdownValue = (label: string): string => {
    const item = breakdown.find((x) => x.name === label);

    if (!item) {
      return '$0.00';
    }

    return item.amount;
  };

  useEffect(() => {
    getBreakdown();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <SplitLayout
          header={
            <ActivitiesChart
              data={createChartData(total, breakdown)}
              info={
                <>
                  <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#fff' }}>{total}</Typography>
                  <Typography sx={{ fontSize: 14, color: '#fff' }}>Total Subscriptions</Typography>
                </>
              }
            />
          }
          component={
            <Activities
              charity={getBreakdownValue('Charity')}
              travel={getBreakdownValue('Travel Rewards')}
              meals={getBreakdownValue('Meals')}
              operations={getBreakdownValue('Operational Overhead')}
              perks={getBreakdownValue('Perks to grow')}
            />
          }
        >
          <Box>
            <SignupContributorButton />
          </Box>
        </SplitLayout>
      </IonContent>
      <NavigationTabBar />
    </IonPage>
  );
};

/**
 * Perks to grow
 * Operational Overhead
 */

export default FundsBreakdownPage;
