import React, { useState } from 'react';

import BeforeLoginLayout from '../layouts/BeforeLoginLayout';
import { IonContent } from '@ionic/react';
import LogoHeadingInfo from '../components/LogoHeadingInfo';
import VolunteerRegister from '../components/VolunteerRegisterForm/VolunteerRegister';

const RegisterPage: React.FC = () => {
  const headerInfo = [
    {
      title: 'Create account',
      text: 'Create an account to start.'
    },
    {
      title: 'Select a charity',
      text: 'Please select one of the following charities to contribute to'
    }
  ]

  const [formStep, setFormStep] = useState(0);

  return (
    <>
    <IonContent>
      <BeforeLoginLayout>
        <LogoHeadingInfo title={headerInfo[formStep].title} text={headerInfo[formStep].text} />
        <VolunteerRegister currentStep={formStep} setStep={setFormStep} />
      </BeforeLoginLayout>
    </IonContent>
    </>
  );
};

export default RegisterPage;
