import { Box, Container, InputAdornment, List, ListItem, Skeleton, TextField, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import StatusDot from '../StatusDot';
import { useHistory } from 'react-router';
import ApplicationPaths from '../../utils/paths';
import { Contributor } from '../../pages/ContributorListPageV2';
import { useEffect, useState } from 'react';

const ContributorList = (props: { contributors: Contributor[]; loading: boolean }): JSX.Element => {
  const history = useHistory();
  const { contributors, loading } = props;
  const handleItemClick = (userId: number) => {
    history.push(ApplicationPaths.CONTRIBUTOR_DETAILS.replace(':id', userId.toString()));
  };

  const [search, setSearch] = useState('');
  const [searchContributors, setSearchContributors] = useState<Contributor[]>([]);

  useEffect(() => {
    setSearchContributors(contributors.filter((x) => !x.name.indexOf(search)));
  }, [search]);

  useEffect(() => {
    setSearchContributors(contributors);
  }, [contributors]);

  return (
    <Container
      sx={{
        paddingBottom: '16px',
      }}
    >
      <TextField
        placeholder="Search..."
        type="search"
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        sx={{
          width: '100%',
          marginY: '12px',
          backgroundColor: '#EEEEEE',
          borderRadius: '5px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'unset',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <List>
        {loading === true && <Skeleton height={200} animation={'pulse'} />}
        {searchContributors.length === 0 && <Typography></Typography>}
        {searchContributors.length > 0 &&
          searchContributors.map((contributor, index) => {
            return (
              <ListItem
                button
                alignItems="center"
                key={index}
                sx={{ paddingX: 0, justifyContent: 'space-between' }}
                divider={true}
                onClick={() => handleItemClick(contributor.id)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StatusDot active={contributor.activeSubscription} />{' '}
                  <Typography sx={{ marginLeft: '9px' }}>{contributor.name}</Typography>
                </Box>
                <ArrowForwardIosIcon fontSize={'small'} />
              </ListItem>
            );
          })}
      </List>
    </Container>
  );
};
export default ContributorList;
