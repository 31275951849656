import { Button, ButtonProps, styled } from '@mui/material';

const PDTButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#06719b',
  color: '#fff',
  boxShadow: 'unset',
  borderRadius: '22px',
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: '#06719b',
    boxShadow: 'unset',
    color: '#fff',
  },
  '&.pdt_grey_button, &.disabled': {
    backgroundColor: '#E0E0E0',
    color: '#000',
  },
}));

export default PDTButton;
