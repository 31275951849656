import { Box, Typography } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

type ActivityData = {
  name: string;
  value: number;
};

export interface ActivityChartProps {
  color: string;
  data: ActivityData[];
  info: React.ReactElement;
}

const ActivityChart = (props: ActivityChartProps): JSX.Element => {
  const { color, data, info } = props;
  const COLORS = ['#D8D8D8', color];

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <PieChart width={200} height={200}>
          <Pie data={data} cornerRadius={8} innerRadius={70} outerRadius={88} paddingAngle={5} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{info}</Box>
      </Box>
    </>
  );
};

export default ActivityChart;
