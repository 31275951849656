import AppContext from './context/appContext';
import ApplicationPaths from './utils/paths';
import ContributeSignupPage from './pages/ContributorSignupPage';
import ContributorListPage from './pages/ContributorListPageV2';
import Dashboard from './components/Dashboard';
import FrontPage from './pages/FrontPage';
import { IonReactRouter } from '@ionic/react-router';
import LoginPage from './pages/LoginPage';
import Logout from './components/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import { Redirect } from 'react-router';
import RegisterPage from './pages/VolunteerRegisterPage';
import { Route } from 'react-router-dom';
import { useContext } from 'react';
import FundsBreakdownPage from './pages/FundsBreakdownPage';
import ContributorDetailsPage from './pages/ContributorDetailsPage';
import ContributorEditPage from './pages/ContributorEditPage';
import ContributorUpdatePaymentMethodPage from './pages/ContributorUpdatePaymentMethodPage';
import ProfilePage from './pages/ProfilePage';
import ProfileEditPage from './pages/ProfileUpdatePage';
import RedeemPage from './pages/RedeemPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ContinueAfterResetPage from './pages/ContinueAfterResetPage';
import RequestCharityPage from './pages/RequestCharityPage';

const Routes = (): JSX.Element => {
  const { isAuthenticated } = useContext(AppContext);

  return (
    <>
      <IonReactRouter>
        <>
          {isAuthenticated ? (
            <>
              <Route path={ApplicationPaths.ROOT} exact render={() => <Redirect to={ApplicationPaths.HOME} />} />
              <Route path={ApplicationPaths.LOGIN} render={() => <Redirect to={ApplicationPaths.HOME} />} />
              <Route path={ApplicationPaths.HOME} component={Dashboard} />
              <Route path={ApplicationPaths.LOGOUT} component={Logout} />
              <ProtectedRoute path={ApplicationPaths.CONTRIBUTOR_SIGNUP} component={ContributeSignupPage} />
              <ProtectedRoute path={ApplicationPaths.CONTRIBUTOR_LIST} component={ContributorListPage} />
              <ProtectedRoute path={ApplicationPaths.CONTRIBUTOR_DETAILS} component={ContributorDetailsPage} />
              <ProtectedRoute path={ApplicationPaths.CONTRIBUTOR_EDIT} component={ContributorEditPage} />
              <ProtectedRoute path={ApplicationPaths.PROFILE} component={ProfilePage} />
              <ProtectedRoute path={ApplicationPaths.PROFILE_EDIT} component={ProfileEditPage} />
              <ProtectedRoute path={ApplicationPaths.REDEEM} component={RedeemPage} />
              <ProtectedRoute
                path={ApplicationPaths.CONTRIBUTOR_PAYMENT_METHOD_UPDATE}
                component={ContributorUpdatePaymentMethodPage}
              />
              <Route path={ApplicationPaths.FUNDS_BREAKDOWN} component={FundsBreakdownPage} />
              <Route path={ApplicationPaths.REQUEST_CHARITY} component={RequestCharityPage} />
            </>
          ) : (
            <>
              <Route path={ApplicationPaths.ROOT} exact render={() => <Redirect to={ApplicationPaths.HOME} />} />
              <Route path={ApplicationPaths.HOME} component={FrontPage} />
              <Route path={ApplicationPaths.LOGIN} component={LoginPage} />
              <Route path={ApplicationPaths.VOLUNTEER_REGISTER} component={RegisterPage} />
              <Route path={ApplicationPaths.FORGOT_PASSWORD} component={ForgotPasswordPage} />
              <Route path={ApplicationPaths.RESET_CONTINUE} component={ContinueAfterResetPage} />
            </>
          )}
        </>
      </IonReactRouter>
    </>
  );
};

export default Routes;
