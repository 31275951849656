import Activity from '../Activity/Activity';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '../TabPanel';
import ActivitySkeleton from '../Activity/ActivitySkeleton';

interface Stat {
  stat: string;
  unit: string;
  description: string;
}

interface PanelStat {
  color: string;
  data: number;
  chart: {
    value: string;
    unit: string;
    label: string;
  };
}

type DashboardSwipeComponentProps = {
  currentTab: number;
  handleSwipe: (index: number) => void;
  stats: Stat[];
  panelStats: PanelStat[] | null;
};

const DashboardSwipe = ({ currentTab, handleSwipe, stats, panelStats }: DashboardSwipeComponentProps): JSX.Element => {
  if (!panelStats) {
    return (
      <SwipeableViews axis={'x'} index={currentTab} onChangeIndex={handleSwipe}>
        {[0, 1, 2].map((value, index) => (
          <TabPanel value={currentTab} index={index} key={index}>
            <Box>
              <ActivitySkeleton />
            </Box>
          </TabPanel>
        ))}
      </SwipeableViews>
    );
  }

  return (
    <SwipeableViews axis={'x'} index={currentTab} onChangeIndex={handleSwipe}>
      {panelStats.map(({ color, data, chart }, index) => (
        <TabPanel value={currentTab} index={index} key={index}>
          <Box>
            <Activity
              stats={stats}
              color={color}
              data={[
                { name: 'Group A', value: 100 - data },
                { name: 'Group B', value: data },
              ]}
              info={
                <Box sx={{ textAlign: 'center' }}>
                  <Box sx={{ color: color, fontSize: '22px', fontWeight: 'bold' }}>
                    {chart.value}
                    <Typography component={'span'} sx={{ fontSize: '9px', color: 'rgba(0,0,0,0.5)' }}>
                      {chart.unit}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: '10px', color: 'rbga(0,0,0,0.73)' }}>{chart.label}</Typography>
                </Box>
              }
            />
          </Box>
        </TabPanel>
      ))}
    </SwipeableViews>
  );
};

export default DashboardSwipe;
