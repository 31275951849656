enum ApplicationPaths {
  ROOT = '/',
  HOME = '/home',
  LOGIN = '/sign-in',
  VOLUNTEER_REGISTER = '/register',
  LOGOUT = '/logout',
  CONTRIBUTOR_SIGNUP = '/contributor-signup',
  CONTRIBUTOR_LIST = '/contributors',
  CONTRIBUTOR_DETAILS = '/contributors/:id',
  CONTRIBUTOR_EDIT = '/edit/contributors/:id',
  CONTRIBUTOR_PAYMENT_METHOD_UPDATE = '/edit/payment-method/contributors/:id',
  FUNDS_BREAKDOWN = '/home/funds-breakdown',
  PROFILE = '/profile',
  PROFILE_EDIT = '/profile-edit',
  REDEEM = '/redeem',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_CONTINUE = '/reset-success',
  REQUEST_CHARITY = '/request-charity',
  COMPONENT = '/component-test',
}

export default ApplicationPaths;
