enum APIEndpoints {
  Login = '/auth/login',
  RegisterVolunteer = '/auth/register',
  UserWhoAmI = '/users/whoami',
  UserTravelPoints = '/users/travelPoints',
  UserDonationHistory = '/users/donationHistory',
  RegisterContributor = '/contributors',
  UpdateContributorProfile = '/contributors/profile/:id',
  UpdateContributorPaymentMethod = '/contributors/paymentMethod/:id',
  GetContributors = '/contributors',
  GetContributorById = '/contributors/:id',
  PaymentPackages = '/paymentPackages',
  Charities = '/charities',
  CharitiesRequest = '/charities/request',
  ContributorsCollectionBreakDown = '/contributors/collectionBreakdown',
  UserImpact = '/users/impact',
  UserEdit = 'users/:id',
  MonthlyStats = '/stats',
  BreakdownStats = '/stats/breakdown',
  Redeem = '/redeem',
}

export default APIEndpoints;
