import { useContext, useEffect } from 'react';

import AppContext from '../context/appContext';
import ApplicationPaths from '../utils/paths';
import { useHistory } from 'react-router-dom';
import { firebaseApp } from '..';

const Logout: React.FC = () => {
  const { updateIsAuthenticated } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    const auth = firebaseApp.auth();
    auth.signOut();
    updateIsAuthenticated(false);
    history.push(ApplicationPaths.HOME);
  }, []);

  return <></>;
};

export default Logout;
