import { Container, Typography, Box } from '@mui/material';
import StatusDot from '../StatusDot';

type Props = {
  name: string;
  active: boolean;
};

const ContributorDetailsHeader = (props: Props): JSX.Element => {
  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StatusDot active={props.active} />{' '}
        <Typography sx={{ marginLeft: '9px', fontSize: '18px', fontWeight: 'bold' }}>{props.name}</Typography>
      </Box>
    </Container>
  );
};

export default ContributorDetailsHeader;
