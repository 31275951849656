import React, { SyntheticEvent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Payment } from '../../pages/ContributorDetailsPage';
import TabPanel from '../TabPanel';
import ContributorDetailsTabNav from './ContributorDetailsTabNav';
import ContributorProfileTab from './ContributorProfileTab';
import ContributorTransactionsTab from './ContributorTransactionsTab';

type Props = {
  name: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  donations: string;
  time: string;
  payments: Payment[];
  active: boolean;
  cc_number: string;
  cc_type: string;
};

const ContributorDetailTabs = (props: Props): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleSwipeChangeIndex = (index: number) => {
    setCurrentTab(index);
  };

  return (
    <>
      <ContributorDetailsTabNav
        onChange={function (event: SyntheticEvent<Element, Event>, newValue: number): void {
          setCurrentTab(newValue);
        }}
        currentTab={currentTab}
      />
      <SwipeableViews axis={'x'} index={currentTab} onChangeIndex={handleSwipeChangeIndex}>
        <TabPanel value={currentTab} index={0}>
          <ContributorProfileTab
            name={props.name}
            email={props.email}
            phone={props.phone}
            address1={props.address1}
            address2={props.address2}
            city={props.city}
            country={props.country}
            state={props.state}
            postal_code={props.postal_code}
            donations={props.donations}
            time={props.time}
            active={props.active}
            cc_number={props.cc_number}
            cc_type={props.cc_type}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <ContributorTransactionsTab payments={props.payments} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
export default ContributorDetailTabs;
