interface TabIndexAttributes {
  id: string;
  'aria-controls': string;
}

export function tabIndexAttributes(index: number): TabIndexAttributes {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}