import { Box } from '@mui/material';
import React from 'react';

type Props = {
  active: boolean;
};

const StatusDot = (props: Props): JSX.Element => {
  return (
    <>
      <Box
        component="span"
        sx={{
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: props.active ? '#86D546' : '#DCDCDC',
        }}
      ></Box>
    </>
  );
};

export default StatusDot;
