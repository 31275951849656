import { createTheme } from '@mui/material/styles';

export const pdtTheme = createTheme({
  palette: {
    primary: {
      main: '#06719b',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
});
