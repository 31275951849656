import * as yup from 'yup';
import { Button, Container } from '@mui/material';
import APIEndpoints from '../../utils/endpoints';
import DonationPackages from './DonationPackages';
import PaymentDetails from './PaymentDetails';
import PersonalDetails from './PersonalDetails';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { formatFormErrors } from '../../utils/form';
import pdtClient from '../../lib/api';
import { useFormik, setNestedObjectValues } from 'formik';
import { useSnackbar } from 'notistack';
import { CustomButton } from '../VolunteerRegisterForm/VolunteerRegister';
import { useHistory } from 'react-router';
import ApplicationPaths from '../../utils/paths';
import { cardNumberValidation, securityDigitValidation, cardExpiryValidation } from '../../utils/creditCardValidation';

export interface IContributorRegisterFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  payment_package: string;
  cc_number: string;
  cc_name: string;
  cc_expiry: string;
  cc_security_digits: string;
}

const validationSchemaPackage = yup.object({
  payment_package: yup.string().required('Payment package is required'),
});

const validationSchemaPersonal = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phone: yup.string().required('Phone is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  postal_code: yup.string().required('Postal Code is required'),
});

const validationSchemaPayment = yup.object({
  cc_number: cardNumberValidation,
  cc_name: yup.string().required('Cardholder name is required'),
  cc_security_digits: securityDigitValidation,
  cc_expiry: cardExpiryValidation,
});

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
};

const ContributorRegister = ({ currentStep, setStep }: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [formState, setFormState] = useState({
    loading: false,
    buttonText: 'Confirm',
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
      cc_number: '',
      cc_name: '',
      cc_security_digits: '',
      cc_expiry: '',
      payment_package: '',
    },
    validationSchema: () => {
      if (currentStep === 1) {
        return validationSchemaPersonal;
      }
      if (currentStep === 2) {
        return validationSchemaPayment;
      }

      return validationSchemaPackage;
    },
    onSubmit: async (values) => {
      const cc_expiry = format(new Date(values.cc_expiry), 'MM/yy');
      const formValues = {
        ...values,
        first_name: values.firstName,
        last_name: values.lastName,
        cc_number: values.cc_number.replaceAll(' ', ''),
        cc_expiry,
      };
      try {
        setFormState({ loading: true, buttonText: 'Submitting...' });
        await pdtClient.post(APIEndpoints.RegisterContributor, formValues, { clearCacheEntry: true });
        enqueueSnackbar('Subscriber registered successfully', { variant: 'success' });
        formik.resetForm();
        history.push(ApplicationPaths.CONTRIBUTOR_LIST);
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          enqueueSnackbar(`An validation error occurred`, { variant: 'error' });
        }
        if (error.response.data.errorMessage) {
          enqueueSnackbar(`Error: ${error.response.data.errorMessage}`, { variant: 'error' });
        } else {
          enqueueSnackbar(`An error occurred`, { variant: 'error' });
        }
      } finally {
        setFormState({ loading: false, buttonText: 'Confirm' });
      }
    },
  });

  const [packageAmount, setPackageAmount] = useState('$0.00');

  const renderFormStep = (step: number) => {
    switch (step) {
      case 1:
        return <PersonalDetails formik={formik} />;

      case 2:
        return <PaymentDetails formik={formik} packageAmount={packageAmount} />;

      default:
        return <DonationPackages formik={formik} updateAmount={setPackageAmount} />;
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {renderFormStep(currentStep)}
        <Container maxWidth="xs">
          {currentStep < 2 && (
            <CustomButton
              sx={{
                mt: 6,
                mb: 2,
                backgroundColor: '#E0E0E0',
                color: '#000',
                boxShadow: 'unset',
                '&:hover': {
                  backgroundColor: '#E0E0E0',
                  boxShadow: 'unset',
                  color: '#000',
                },
              }}
              fullWidth
              onClick={async () => {
                const errors = await formik.validateForm();

                if (Object.keys(errors).length === 0) {
                  // Form is valid, do any success call
                  setStep(currentStep + 1);
                } else {
                  formik.setTouched(setNestedObjectValues(errors, true));
                }
              }}
              variant="contained"
            >
              Next
            </CustomButton>
          )}

          {currentStep == 2 && (
            <Button
              sx={{
                mt: 6,
                mb: 2,
                backgroundColor: '#06719b',
                color: '#fff',
                boxShadow: 'unset',
                borderRadius: '22px',
                textTransform: 'unset',
                '&:hover': {
                  backgroundColor: '#06719b',
                  boxShadow: 'unset',
                  color: '#fff',
                },
              }}
              fullWidth
              type="submit"
              variant="contained"
              disabled={formState.loading}
            >
              {formState.buttonText}
            </Button>
          )}

          {currentStep > 0 && (
            <p style={{ textDecoration: 'underline', textAlign: 'center' }} onClick={() => setStep(currentStep - 1)}>
              Back
            </p>
          )}
        </Container>
      </form>
    </>
  );
};

export default ContributorRegister;
