import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { format } from 'date-fns';
import { Payment } from '../pages/ContributorDetailsPage';
import ProfileDetail from './ProfileDetail';

type Props = {
  payments: Payment[];
};

const TransactionHistory = ({ payments }: Props): JSX.Element => {
  if (payments.length === 0) {
    return <Typography>No transactions to show</Typography>;
  }

  return (
    <List>
      {payments.map(({ amount, created_at }, index) => {
        return (
          <ListItem key={index} sx={{ padding: '0px' }}>
            <ProfileDetail label={format(new Date(created_at), 'dd LLLL, yyyy')} value={amount} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default TransactionHistory;
