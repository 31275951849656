import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';

interface Props {
  title?: string;
  logOut?: boolean;
}

const Header: React.FC<Props> = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <Container>
          <Grid
            container
            alignContent={'center'}
            alignItems={'center'}
            spacing={1}
            style={{
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
          >
            <Grid item sm={11} style={{ display: 'flex' }}>
              <img
                src="./assets/pdt_logo.svg"
                alt="PDT Logo"
                className="logo"
                style={{
                  maxWidth: '70px',
                  margin: '0',
                  display: 'inline-block',
                }}
              />
              <IonTitle style={{ fontWeight: 700, color: 'white', fontSize: '26px' }}>Purpose Driven Travel</IonTitle>
            </Grid>
          </Grid>
        </Container>
      </IonToolbar>
    </IonHeader>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  logOut: PropTypes.bool,
};

export default Header;
