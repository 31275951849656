import { Container } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import DashboardTabNav from '../components/VolunteerDashboard/DashboardTabNav';
import Box from '@mui/material/Box';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import LocalAirport from '@mui/icons-material/LocalAirport';
import { IonContent } from '@ionic/react';
import NavigationTabBar from '../components/NavigationTabBar';
import DashboardHeading from '../components/DashboardHeading';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import { useHistory } from 'react-router';
import ApplicationPaths from '../utils/paths';
import SplitLayout from '../layouts/SplitLayout';
import DashboardSwipe from '../components/VolunteerDashboard/DashboardSwipe';
import SignupContributorButton from '../components/Buttons/SignupContributorButton';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import { format } from 'date-fns';
import AppContext from '../context/appContext';
import { dollarToNumber } from '../utils/dollarToNumber';

type Breakdown = {
  name: string;
  amount: string;
};

type StatResponse = {
  activeContributors: number;
  breakdown: Breakdown[];
  funds: string;
  latestSignupDate: string;
  meals: { current: number; total: number };
  totalContributions: string;
  travel: string;
};

const VolunteerDashboardPage = (): JSX.Element => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [totalStats, setTotalStats] = useState({
    totalCharity: '$0',
    totalMeals: 0,
    totalRewards: '$0',
  });

  const [monthlyStats, setMonthlyStats] = useState<null | StatResponse>(null);
  const { me } = useContext(AppContext);

  const tabClickHandler = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSwipeChangeIndex = (index: number) => {
    setCurrentTab(index);
  };

  function generateHeader(tab: number) {
    switch (tab) {
      case 1:
        return <DashboardHeading text={totalStats.totalCharity} subText={`Total Funds Raised for Charities`} />;
      case 2:
        return <DashboardHeading text={totalStats.totalMeals.toString()} subText={'Total meals provided'} />;
      default:
        return (
          <DashboardHeading
            text={totalStats.totalRewards}
            subText={'Total Travel Rewards'}
            icon={<LocalAirportIcon />}
          />
        );
    }
  }

  useEffect(() => {
    const getImpact = async () => {
      try {
        const response = await pdtClient.get(APIEndpoints.UserImpact);
        const { travelPoints, charity, meals } = response.data.data;
        setTotalStats({
          totalCharity: charity,
          totalRewards: travelPoints,
          totalMeals: meals,
        });
      } catch (e) {
        console.log('Error Occurred');
        throw e;
      }
    };

    const getMonthlyStats = async () => {
      try {
        const response = await pdtClient.get(APIEndpoints.MonthlyStats);
        const stats: StatResponse = response.data.data;
        setMonthlyStats(stats);
      } catch (error) {
        console.log(error);
      }
    };
    getImpact();
    getMonthlyStats();
  }, []);

  useEffect(() => {
    // Needs to request charity
    if (me) {
      if (!me.charity && !me.visited_request_page && !me.charity_requested)
        history.push(ApplicationPaths.REQUEST_CHARITY);
    }
  }, [me]);

  const generateStats = () => {
    return [
      {
        stat: monthlyStats?.totalContributions.toString() || '0',
        unit: '/month',
        description: 'Total Subscriptions',
      },
      {
        stat: monthlyStats?.activeContributors.toString() || '0',
        unit: '',
        description: 'Active Subscribers',
      },
      {
        stat: monthlyStats
          ? monthlyStats.latestSignupDate !== null
            ? format(new Date(monthlyStats.latestSignupDate), 'LLL dd, yyyy')
            : '-'
          : '-',
        unit: '',
        description: 'Latest Sign-up',
      },
    ];
  };

  const generatePanels = () => {
    console.log(monthlyStats);
    let panels = null;
    if (monthlyStats) {
      //Extra pad for travel points -> make it circular at least
      let travelData =
        (dollarToNumber(monthlyStats.travel) / dollarToNumber(monthlyStats?.totalContributions)) * 100 || 0;
      if (travelData < 5) {
        travelData = travelData * 3;
      }
      panels = [
        {
          color: '#8290EA',
          data: travelData,
          chart: {
            value: monthlyStats?.travel || '0',
            unit: '/month',
            label: 'In Travel Rewards',
          },
        },
        {
          color: '#F8CF2C',
          data: (dollarToNumber(monthlyStats.funds) / dollarToNumber(monthlyStats?.totalContributions)) * 100 || 0,
          chart: {
            value: monthlyStats.funds || '0',
            unit: '/month',
            label: 'In Funds Raised',
          },
        },
        {
          color: '#22CAE0',
          data: (monthlyStats.meals.current / monthlyStats.meals.total) * 100 || 0,
          chart: {
            value: monthlyStats.meals.current.toString(),
            unit: '/month',
            label: 'Meals Provided',
          },
        },
      ];
    }

    return panels;
  };

  return (
    <React.Fragment>
      <IonContent>
        <SplitLayout
          header={
            <>
              {generateHeader(currentTab)}
              <DashboardTabNav onChange={tabClickHandler} currentTab={currentTab} />
            </>
          }
          component={
            <DashboardSwipe
              currentTab={currentTab}
              handleSwipe={handleSwipeChangeIndex}
              stats={generateStats()}
              panelStats={generatePanels()}
            />
          }
        >
          <Box
            sx={{
              marginTop: '50px',
            }}
          >
            <Container>
              <ButtonWithIcon
                onClick={() => {
                  history.push(ApplicationPaths.REDEEM);
                }}
                text={'Redeem Rewards'}
                icon={<LocalAirport style={{ fontSize: '30px' }} />}
              />
              <SignupContributorButton />
            </Container>
          </Box>
        </SplitLayout>
      </IonContent>
      <NavigationTabBar />
    </React.Fragment>
  );
};

export default VolunteerDashboardPage;
