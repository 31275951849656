import { IonContent, IonPage } from '@ionic/react';
import { Box, Container } from '@mui/material';
import React from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import LogoHeadingInfo from '../components/LogoHeadingInfo';
import BeforeLoginLayout from '../layouts/BeforeLoginLayout';

const ContinueAfterResetPage = () => {
  return (
    <>
      <IonContent>
        <BeforeLoginLayout>
          <div style={{ fontFamily: 'Inter V_Bold' }}>
            <LogoHeadingInfo
              title="Password Reset Completed"
              text="You can now close this browser tab and login to the app with your new password."
            />
          </div>
        </BeforeLoginLayout>
      </IonContent>
    </>
  );
};

export default ContinueAfterResetPage;
