import BeforeLoginLayout from '../layouts/BeforeLoginLayout';
import Button, { ButtonProps } from '@mui/material/Button';
import { IonContent } from '@ionic/react';
import LogoHeadingInfo from '../components/LogoHeadingInfo';
import { useHistory } from 'react-router';
import { styled } from '@mui/material/styles';
import PDTButton from '../components/Buttons/PDTButton';

const FrontPage = (): JSX.Element => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push('/sign-in');
  };

  const handleRegisterClick = () => {
    history.push('/register');
  };
  return (
    <>
      <IonContent>
        <BeforeLoginLayout>
          <div style={{ width: '100%' }}>
            <LogoHeadingInfo title="" text="Earn Free Trips Inspired by Purpose" />
            <div
              style={{
                maxWidth: '540px',
                padding: '0 10px',
                marginTop: '60px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <PDTButton variant="contained" fullWidth onClick={handleSignInClick} style={{ marginBottom: '14px' }}>
                Sign in
              </PDTButton>
              <PDTButton variant="contained" fullWidth className="pdt_grey_button" onClick={handleRegisterClick}>
                Create account
              </PDTButton>
            </div>
          </div>
        </BeforeLoginLayout>
      </IonContent>
    </>
  );
};

export default FrontPage;
