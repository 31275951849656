import { Container } from '@mui/material';
import React from 'react';

type Props = {
  children:  React.ReactNode;
};

const BeforeLoginLayout = ({ children }: Props): JSX.Element => {
  return <Container style={{ position: 'relative', top: '105px' }}>{children}</Container>;
};

export default BeforeLoginLayout;
