import { IonContent, IonPage } from '@ionic/react';
import React, { useContext, useState } from 'react';

import AppContext from '../context/appContext';
import { Box, Container } from '@mui/material';
import ContributorRegister from '../components/ContributorRegisterForm/ContributorRegister';
import NavigationTabBar from '../components/NavigationTabBar';

const ContributeSignupPage: React.FC = () => {
  const { hasRole } = useContext(AppContext);
  const [formStep, setFormStep] = useState(0);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Box sx={{ textAlign: 'center', marginTop: '32px' }} className="notch_safe_padding_top">
          <img
            src="./assets/pdt_logo.svg"
            alt="PDT Logo"
            className="logo"
            style={{
              margin: '0',
              display: 'inline-block',
              maxWidth: '114px',
            }}
          />
        </Box>
        <Container style={{ marginTop: '40px' }} maxWidth={'md'}>
          <ContributorRegister currentStep={formStep} setStep={setFormStep} />
        </Container>
      </IonContent>
      {hasRole(['ADMINISTRATOR', 'VOLUNTEER']) && <NavigationTabBar />}
    </IonPage>
  );
};

export default ContributeSignupPage;
