import { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import { format } from 'date-fns';
import { formatFormErrors } from '../utils/form';
import valid from 'card-validator';
import ContributorDetailsHeader from '../components/VolunteerDashboard/ContributorDetailsHeader';
import { IonContent, IonPage } from '@ionic/react';
import SplitLayout from '../layouts/SplitLayout';
import NavigationTabBar from '../components/NavigationTabBar';
import UpdatePaymentMethod from '../components/VolunteerDashboard/UpdatePaymentMethod';
import { useHistory, useParams } from 'react-router';
import ApplicationPaths from '../utils/paths';
import { cardExpiryValidation, cardNumberValidation, securityDigitValidation } from '../utils/creditCardValidation';

export interface IContributorUpdatePaymentMethodFormValues {
  cc_number: string;
  cc_expiry: string;
  cc_security_digits: string;
  cc_postal_code: string;
  cc_first_name: string;
  cc_last_name: string;
}

const validationSchema = yup.object({
  cc_number: cardNumberValidation,
  cc_first_name: yup.string().required('First name is required'),
  cc_last_name: yup.string().required('Last name is required'),
  cc_security_digits: securityDigitValidation,
  cc_expiry: cardExpiryValidation,
  cc_postal_code: yup.string().required('Postal code is required'),
});

const ContributorUpdatePaymentMethodPage = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const [info, setInfo] = useState({ name: 'name', status: false });
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Update');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await pdtClient.get(APIEndpoints.GetContributorById.replace(':id', params.id || ''));
        const { name, subscription_active } = response.data.data;
        setLoading(true);
        setInfo({
          name,
          status: subscription_active,
        });
        setLoading(false);
      } catch (e) {
        enqueueSnackbar('Error Occurred while retrieving subscriber information!', { variant: 'error' });
      }
    };
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      cc_number: '',
      cc_first_name: '',
      cc_last_name: '',
      cc_security_digits: '',
      cc_expiry: '',
      cc_postal_code: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const cc_expiry = format(new Date(values.cc_expiry), 'MM/yy');
      try {
        setLoading(true);
        setButtonText('Updating...');
        await pdtClient.put(APIEndpoints.UpdateContributorPaymentMethod.replace(':id', params.id || ''), {
          ...values,
          cc_number: values.cc_number.replaceAll(' ', ''),
          cc_expiry,
        });
        enqueueSnackbar('Payment method updated!', { variant: 'success' });
        formik.resetForm();
        history.push(ApplicationPaths.CONTRIBUTOR_DETAILS.replace(':id', params.id || ''));
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
        }
        if (error.response.data.errorMessage) {
          enqueueSnackbar(`Error: ${error.response.data.errorMessage}`, { variant: 'error' });
        } else {
          enqueueSnackbar(`An error occurred`, { variant: 'error' });
        }
      } finally {
        setButtonText('Update');
        setLoading(false);
      }
    },
  });

  const onClickConfirm = () => {
    formik.submitForm();
  };

  return (
    <>
      <IonPage>
        <IonContent>
          <SplitLayout
            header={<ContributorDetailsHeader name={info.name} active={info.status} />}
            component={
              <Box padding={4}>
                <form onSubmit={formik.handleSubmit}>
                  <UpdatePaymentMethod formik={formik} />
                </form>
              </Box>
            }
          >
            <>
              <Button
                sx={{
                  mb: 4,
                  backgroundColor: '#06719b',
                  color: '#fff',
                  boxShadow: 'unset',
                  borderRadius: '22px',
                  textTransform: 'unset',
                  '&:hover': {
                    backgroundColor: '#06719b',
                    boxShadow: 'unset',
                    color: '#fff',
                  },
                }}
                fullWidth
                type="submit"
                variant="contained"
                onClick={onClickConfirm}
                disabled={loading}
              >
                {buttonText}
              </Button>
            </>
          </SplitLayout>
        </IonContent>
        <NavigationTabBar />
      </IonPage>
    </>
  );
};

export default ContributorUpdatePaymentMethodPage;
