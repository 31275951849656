import React from 'react';
import { Container, Box, Card } from '@mui/material';

type Props = {
  /** Header component for page  */
  header: React.ReactNode;
  /** Component immediately after header component  */
  component: React.ReactNode;
  /** Other items to render  */
  children: React.ReactNode;
};

const SplitLayout = ({ header, component, children }: Props): JSX.Element => {
  return (
    <>
      <Box sx={{ backgroundColor: '#000' }} className="dashboard__banner">
        <Container maxWidth={'sm'}>{header}</Container>
        <Box className="dashboard__black-strip"></Box>
      </Box>
      <Container maxWidth={'sm'}>
        <Card
          sx={{
            boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.11)',
            borderRadius: '16px',
          }}
        >
          {component}
        </Card>
      </Container>
      <Box
        sx={{
          marginTop: '50px',
        }}
      ></Box>
      <Container maxWidth={'sm'}>{children}</Container>
    </>
  );
};

export default SplitLayout;
