import React, { createContext, useEffect, useState } from 'react';

import APIEndpoints from '../utils/endpoints';
import PropTypes from 'prop-types';
import pdtClient, { cache } from '../lib/api';
import { firebaseApp } from '..';

type Role = 'ADMIN' | 'VOLUNTEER' | 'CONTRIBUTOR';

type WhoAmI = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  referral_code: string;
  role: string;
  redeem_requested: boolean;
  visited_request_page: boolean; // Client side value only
  charity_requested: boolean;
  charity?: {
    name: string;
  };
};
interface IAppContext {
  roles: Role[];
  updateIsAuthenticated(isAuthenticated: boolean): void;
  isAuthenticated: boolean;
  hasRole(roles: string[]): boolean;
  userName: string;
  loading: boolean;
  me: WhoAmI | null;
  updateMe(update: Partial<WhoAmI>): void;
}

//Initial App Context
export const AppContext = createContext<IAppContext>({
  roles: [],
  isAuthenticated: false,
  updateIsAuthenticated: function (): void {
    // do nothing
  },
  hasRole: function (): boolean {
    return false;
  },
  userName: '',
  loading: false,
  me: null,
  updateMe: function (): void {
    // do nothing
  },
});

export const AppProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [userName, setUserName] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [whoAmI, setWhoAmI] = useState<WhoAmI | null>(null);

  const updateIsAuthenticated = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  const hasRole = (checkRoles: string[]) => {
    return roles.some((role) => checkRoles.includes(role));
  };

  useEffect(() => {
    // Check if user is sign in
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        updateIsAuthenticated(true);
      }
    });
  }, []);

  useEffect(() => {
    async function getUserInfo() {
      try {
        //Get User Role
        setLoading(true);
        const response = await pdtClient.get(APIEndpoints.UserWhoAmI);
        const me = response.data.data;
        const { role, name } = me;
        setWhoAmI({ ...me, visited_request_page: false });
        setRoles([role]);
        setUserName(name);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    if (!isAuthenticated) {
      setRoles([]);
      setUserName('');
      setWhoAmI(null);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      cache.store.clear(); //Clear axios cache
      return;
    }

    getUserInfo();
  }, [isAuthenticated]);

  const updateMe = (update: Partial<WhoAmI>) => {
    setWhoAmI(Object.assign(whoAmI, update));
  };

  return (
    <AppContext.Provider
      value={{
        roles,
        updateIsAuthenticated,
        isAuthenticated,
        hasRole,
        userName,
        loading,
        me: whoAmI,
        updateMe,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.any,
};

export default AppContext;
