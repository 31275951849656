import { Button, Divider, Typography, Box } from '@mui/material';
import React from 'react';
import ProfileDetail from '../ProfileDetail';
import StatusDot from '../StatusDot';
import { useHistory, useParams } from 'react-router';
import ApplicationPaths from '../../utils/paths';
import { format } from 'date-fns';

type Props = {
  name: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  donations: string;
  time: string;
  active: boolean;
  cc_number: string;
  cc_type: string;
};

const ContributorProfileTab = (props: Props): JSX.Element => {
  const history = useHistory();
  const params = useParams<{ id: string | undefined }>();

  return (
    <>
      <ProfileDetail label={'Name'} value={props.name} />
      <ProfileDetail
        label={'Status'}
        component={<StatusDot active={props.active} />}
        value={props.active ? 'Active' : 'Not Active'}
      />
      <ProfileDetail label={'Active Since'} value={format(new Date(props.time), 'MMMM dd, yyyy')} />
      <ProfileDetail label={'Total Subscriptions'} value={props.donations} />
      <Divider />

      <Typography sx={{ fontSize: '11.25px', color: 'rgba(0,0,0,0.54)', marginY: '12px' }}>Personal</Typography>
      <ProfileDetail label={'Email'} value={props.email} />
      <ProfileDetail label={'Phone number'} value={props.phone} />
      <ProfileDetail label={'Address'} value={props.address1} />
      <ProfileDetail label={'Address 2'} value={props.address2} />
      <ProfileDetail label={'City'} value={props.city} />
      <ProfileDetail label={'State/Province'} value={props.state} />
      <ProfileDetail label={'Country'} value={props.country} />
      <ProfileDetail label={'Postal Code'} value={props.postal_code} />
      <Divider />

      <Typography sx={{ fontSize: '11.25px', color: 'rgba(0,0,0,0.54)', marginY: '12px' }}>Payment Method</Typography>
      <ProfileDetail label={'Card'} value={props.cc_number} />
      <ProfileDetail label={'Card Type'} value={props.cc_type} />
      <Divider />

      <Button
        variant="text"
        size="large"
        onClick={() => history.push(ApplicationPaths.CONTRIBUTOR_PAYMENT_METHOD_UPDATE.replace(':id', params.id || ''))}
        sx={{ width: '100%', color: '#000', textTransform: 'unset', paddingY: '18px' }}
      >
        Change Payment Method{' '}
      </Button>

      <Divider />
      <Button
        variant="text"
        size="large"
        onClick={() => history.push(ApplicationPaths.CONTRIBUTOR_EDIT.replace(':id', params.id || ''))}
        sx={{ width: '100%', color: '#000', textTransform: 'unset', paddingY: '18px', marginBottom: '-24px' }}
      >
        Update Profile{' '}
      </Button>
    </>
  );
};

export default ContributorProfileTab;
