import firebase from 'firebase/app';
import 'firebase/auth';

export const firebaseInit = (): firebase.app.App => {
  const firebaseConfig = {
    apiKey: 'AIzaSyDPi63i5D-GXlUvPLkGpJdX7--bO3gIz6g',
    authDomain: 'purpose-driven-travel.firebaseapp.com',
    projectId: 'purpose-driven-travel',
    storageBucket: 'purpose-driven-travel.appspot.com',
    messagingSenderId: '82203684148',
    appId: '1:82203684148:web:d714e398b999b2094a8961',
    measurementId: 'G-CKF2NEEMYV',
  };
  return firebase.initializeApp(firebaseConfig);
};
