import { firebaseApp } from '..';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

// Create `axios-cache-adapter` instance
export const cache = setupCache({
  maxAge: 1 * 60 * 1000,
  invalidate: async (config: any, request) => {
    if (request.clearCacheEntry || request.method !== 'GET') {
      await config.store?.removeItem(config.uuid);
    }
  },
});

const pdtClient = () => {
  // const baseURL = 'https://purpose-driven-travel.nn.r.appspot.com/v1';

  // Use below to connect to dev environment
  const baseURL = process.env.REACT_APP_API_URL;
  const instance = axios.create({
    baseURL,
    adapter: cache.adapter,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add the Authorization token
  instance.interceptors.request.use(async function (config) {
    const auth = firebaseApp.auth();
    if (auth.currentUser) {
      let token = '';
      try {
        token = await auth.currentUser.getIdToken();
      } catch (error) {
        console.error('Error in interceptor');
      }
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }
    return config;
  });

  return instance;
};

export default pdtClient();
