import { Box, Divider, Grid, Typography, TextField, Chip } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';

import { FormikProps } from 'formik';
import { IContributorRegisterFormValues } from './ContributorRegister';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { addMonths } from 'date-fns';

type Props = {
  formik: FormikProps<IContributorRegisterFormValues>;
  packageAmount: string;
};

const PaymentDetails = ({ formik, packageAmount }: Props): JSX.Element => {
  const [value, setValue] = useState<Date | null>(addMonths(new Date(), 1));

  useEffect(() => {
    if (!value) {
      formik.setFieldValue('cc_expiry', addMonths(new Date(), 1));
      return;
    }
    formik.setFieldValue('cc_expiry', value?.toString());
  }, [value]);

  return (
    <>
      <Typography
        variant={'h2'}
        sx={{ fontSize: '24px', textAlign: 'center' }}
        gutterBottom={true}
        style={{ marginTop: 35 }}
      >
        Credit Card Information
      </Typography>

      <Box>
        <Typography sx={{ fontSize: '15px', color: 'rgba(0,0,0,0.54)' }}>Monthly Total:</Typography>
        <Typography sx={{ fontSize: '34px' }}>{packageAmount}</Typography>
        <Chip label="All payments in USD" color="warning" />
      </Box>

      <Divider sx={{ mt: 4, mb: 4 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <InputMask
            id="cc_number"
            name="cc_number"
            onChange={formik.handleChange}
            mask="9999 9999 9999 9999"
            value={formik.values.cc_number}
            disabled={false}
          >
            {() => (
              <TextField
                id="cc_number"
                variant="filled"
                onChange={formik.handleChange}
                fullWidth
                autoComplete="cc-number"
                label="Card Number"
                helperText={formik.errors.cc_number}
                error={formik.touched.cc_number && Boolean(formik.errors.cc_number)}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="filled"
            fullWidth
            id="cc_name"
            name="cc_name"
            value={formik.values.cc_name}
            onChange={formik.handleChange}
            label="Cardholder name"
            helperText={formik.errors.cc_name}
            error={formik.touched.cc_name && Boolean(formik.errors.cc_name)}
          />
        </Grid>
        <Grid item xs={4} md={6}>
          <TextField
            variant="filled"
            fullWidth
            id="cc_security_digits"
            name="cc_security_digits"
            value={formik.values.cc_security_digits}
            onChange={formik.handleChange}
            autoComplete="cc-csc"
            label="CSV"
            helperText={formik.errors.cc_security_digits}
            error={formik.touched.cc_security_digits && Boolean(formik.errors.cc_security_digits)}
            type="number"
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <DatePicker
            views={['month', 'year']}
            label="Expiry Year and Month"
            minDate={addMonths(new Date(), 1)}
            value={value}
            onChange={setValue}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                fullWidth
                helperText={formik.errors.cc_expiry}
                error={formik.touched.cc_expiry && Boolean(formik.errors.cc_expiry)}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentDetails;
