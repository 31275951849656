import * as yup from 'yup';

import APIEndpoints from '../../utils/endpoints';
import ApplicationPaths from '../../utils/paths';
import { AxiosResponse } from 'axios';
import Button, { ButtonProps } from '@mui/material/Button';
import CharitySelection from './CharitySelection';
import { Link } from 'react-router-dom';
import PersonalDetails from './PersonalDetails';
import { formatFormErrors } from '../../utils/form';
import pdtClient from '../../lib/api';
import { useFormik, setNestedObjectValues } from 'formik';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import PDTButton from '../Buttons/PDTButton';

export const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.pdt_blue_button': {
    backgroundColor: '#06719b',
    color: '#fff',
  },
  borderRadius: '20px',
  textTransform: 'none',
  '&.pdt_grey_button': {
    backgroundColor: '#e0e0e0',
    color: '#212121',
  },
}));

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
};

type RegisterResponse = {
  message: string;
  data: null;
};

export interface IRegisterFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  phone: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  charity: string;
}

const validationSchemaPersonal = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  postal_code: yup.string().required('Postal Code is required'),
});

const validationSchemaCharity = yup.object({
  charity: yup.string().required('Charity is required'),
});

const VolunteerRegister = ({ currentStep, setStep }: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      firstName: '',
      lastName: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
      charity: '',
    },
    validationSchema: currentStep === 0 ? validationSchemaPersonal : validationSchemaCharity,
    onSubmit: async (values) => {
      try {
        const formValues = {
          ...values,
          first_name: values.firstName,
          last_name: values.lastName,
        };
        const response: AxiosResponse<RegisterResponse> = await pdtClient.post(
          APIEndpoints.RegisterVolunteer,
          formValues,
        );
        enqueueSnackbar(response.data.message, { variant: 'success' });
        history.push('/home');
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          setStep(0);
        }
        if (error.response.data.errorMessage) {
          enqueueSnackbar(`Error: ${error.response.data.errorMessage}`, { variant: 'error' });
        } else {
          enqueueSnackbar(`An error occurred`, { variant: 'error' });
        }
      }
    },
  });

  const renderFormStep = (step: number) => {
    switch (step) {
      case 1:
        return <CharitySelection formik={formik} />;

      default:
        return <PersonalDetails formik={formik} />;
    }
  };

  return (
    <>
      <Box display={'flex'} flexDirection="column" alignItems={'center'}>
        {renderFormStep(currentStep)}
        <Grid container maxWidth="sm" rowSpacing={1} columnSpacing={5}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <form onSubmit={formik.handleSubmit}>
              <div style={{ marginTop: '32px' }}>
                {currentStep < 1 && (
                  <Grid>
                    <PDTButton
                      fullWidth
                      onClick={async () => {
                        // Validate current step
                        const errors = await formik.validateForm();
                        if (Object.keys(errors).length === 0) {
                          // Form is valid, do any success call
                          setStep(currentStep + 1);
                        } else {
                          formik.setTouched(setNestedObjectValues(errors, true));
                        }
                      }}
                      variant="contained"
                      className="pdt_grey_button"
                    >
                      Next
                    </PDTButton>
                  </Grid>
                )}

                {currentStep == 1 && (
                  <PDTButton
                    type="submit"
                    disabled={formik.isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="pdt_blue_button"
                  >
                    {formik.isSubmitting ? 'Creating...' : 'Create Account'}
                  </PDTButton>
                )}
                {currentStep > 0 && (
                  <p
                    style={{ textDecoration: 'underline', textAlign: 'center' }}
                    onClick={() => setStep(currentStep - 1)}
                  >
                    Back
                  </p>
                )}
              </div>
            </form>
            <p style={{ marginTop: 30, textAlign: 'center' }}>
              Already a user?{' '}
              <Link to={ApplicationPaths.LOGIN} style={{ color: '#06719b' }}>
                Login
              </Link>
            </p>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VolunteerRegister;
