import { IonContent, IonPage } from '@ionic/react';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import { Container, Box } from '@mui/material';
import React from 'react';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import NavigationTabBar from '../components/NavigationTabBar';
import SignupContributorButton from '../components/Buttons/SignupContributorButton';
import ContributorDetailsHeader from '../components/VolunteerDashboard/ContributorDetailsHeader';
import ContributorDetailTabs from '../components/VolunteerDashboard/ContributorDetailTabs';
import SplitLayout from '../layouts/SplitLayout';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom';

export type Payment = {
  id: number;
  amount: string;
  created_at: string;
};

const ContributorDetailsPage = (): JSX.Element => {
  const params = useParams<{ id: string | undefined }>();
  console.log(params);

  const [info, setInfo] = useState({ setName: 'name', status: false });
  const [details, setDetails] = useState({
    email: '',
    name: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    postal_code: '',
    donations: '$0',
    time: new Date().toString(),
    cc_number: '',
    cc_type: '',
  });

  const [payments, setPayments] = useState<Payment[]>([]);

  const getData = async () => {
    try {
      const response = await pdtClient.get(APIEndpoints.GetContributorById.replace(':id', params.id || ''));
      console.log(response);
      const { name, subscription_active, payments } = response.data.data;
      const {
        email,
        phone,
        address1,
        address2,
        city,
        country,
        state,
        postal_code,
        donations_total_to_date,
        created_at,
        payment_method,
      } = response.data.data;
      setInfo({
        setName: name,
        status: subscription_active,
      });
      setDetails({
        name: name,
        email: email,
        phone: phone,
        address1: address1,
        address2: address2,
        city: city,
        country: country,
        state: state,
        postal_code: postal_code,
        donations: donations_total_to_date,
        time: created_at,
        cc_number: payment_method.cc_number,
        cc_type: payment_method.cc_type,
      });
      setPayments(payments);
      console.log('worked');
    } catch (e) {
      console.log('Error Occurred while getting data');
      throw e;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <SplitLayout
          header={<ContributorDetailsHeader name={info.setName} active={info.status} />}
          component={
            <ContributorDetailTabs
              active={info.status}
              name={details.name}
              email={details.email}
              phone={details.phone}
              address1={details.address1}
              address2={details.address2}
              city={details.city}
              country={details.country}
              state={details.state}
              postal_code={details.postal_code}
              donations={details.donations}
              time={details.time}
              payments={payments}
              cc_number={details.cc_number}
              cc_type={details.cc_type}
            />
          }
        >
          <Container>
            <SignupContributorButton />
          </Container>
        </SplitLayout>
      </IonContent>
      <NavigationTabBar />
    </IonPage>
  );
};

export default ContributorDetailsPage;
