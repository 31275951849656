import { Card, CardContent, Typography, Box } from '@mui/material';
import { BUCKET_COLORS } from '../../utils/bucketColors';
import ActivityProgress from './ActivityProgress';

export type Breakdown = {
  name: string;
  amount: string;
};

interface Props {
  travel: string;
  meals: string;
  charity: string;
  operations: string;
  perks: string;
}

const Activities = (props: Props): JSX.Element => {
  const isZeroDollars = (amount: string) => {
    return amount === '$0.00';
  };
  return (
    <Card sx={{ padding: '15px 22px 15px 15px', boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.11)' }}>
      <CardContent>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'rgba(0,0,0,0.5)',
          }}
        >
          Full Breakdown{' '}
        </Typography>
        <Box
          component="div"
          sx={{
            height: '1px',
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0.2)',
            my: 2,
          }}
        ></Box>

        <ActivityProgress
          value={isZeroDollars(props.meals) ? 0 : 90}
          color={BUCKET_COLORS['Meals']}
          label={props.meals}
          buttonText="Meals Provided"
          infoValue="$20.00"
          infoUnit="/month"
          infoLabel="Monthly contribution per package: Package 1: $2/month"
        />

        <ActivityProgress
          value={isZeroDollars(props.meals) ? 0 : 5}
          color={BUCKET_COLORS['Charity']}
          label={props.charity}
          buttonText="Funds Raised"
          infoValue="$20.00"
          infoUnit="/month"
          infoLabel="Monthly contribution per package: Package 1: $2/month"
        />
        <ActivityProgress
          value={isZeroDollars(props.meals) ? 0 : 3.14}
          color={BUCKET_COLORS['Operational Overhead']}
          label={props.operations}
          buttonText="Operations"
          infoValue="$20.00"
          infoUnit="/month"
          infoLabel="Monthly contribution per package: Package 1: $2/month"
        />
        <ActivityProgress
          value={isZeroDollars(props.meals) ? 0 : 1.25}
          color={BUCKET_COLORS['Travel Rewards']}
          label={props.travel}
          buttonText="Travel Rewards"
          infoValue="$20.00"
          infoUnit="/month"
          infoLabel="Monthly contribution per package: Package 1: $2/month"
        />
        <ActivityProgress
          value={isZeroDollars(props.meals) ? 0 : 0.6 * 5}
          color={BUCKET_COLORS['Perks to grow']}
          label={props.perks}
          buttonText="Perks and Contest"
          infoValue="$20.00"
          infoUnit="/month"
          infoLabel="Monthly contribution per package: Package 1: $2/month"
        />
      </CardContent>
    </Card>
  );
};

export default Activities;
