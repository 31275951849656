import UserInfoCommon from './UserInfoCommon';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { cashOutline, pricetagOutline } from 'ionicons/icons';

type Props = {
  totalPayments: number;
  donationAmount: string;
};

const ContributorInfo: React.FC<Props> = (props: Props) => {
  return (
    <>
      <UserInfoCommon />
      <IonChip>
        <IonIcon icon={pricetagOutline} color="dark" />
        <IonLabel>Total Payments {props.totalPayments}</IonLabel>
      </IonChip>
      <IonChip>
        <IonIcon icon={cashOutline} color="dark" />
        <IonLabel>Donated {props.donationAmount}</IonLabel>
      </IonChip>
    </>
  );
};

export default ContributorInfo;
