import { IonContent, IonPage } from '@ionic/react';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import React, { useContext } from 'react';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import NavigationTabBar from '../components/NavigationTabBar';
import ProfileDetail from '../components/ProfileDetail';
import LogoutIcon from '@mui/icons-material/Logout';
import SplitLayout from '../layouts/SplitLayout';
import { useHistory } from 'react-router';
import ApplicationPaths from '../utils/paths';
import AppContext from '../context/appContext';

const ProfilePage = () => {
  const history = useHistory();
  const { me } = useContext(AppContext);
  return (
    <IonPage>
      <IonContent>
        <SplitLayout
          header={
            <>
              <Container>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                    My Profile
                  </Typography>
                </Box>
              </Container>
            </>
          }
          component={
            <>
              <Box sx={{ m: 3 }}>
                <ProfileDetail label={'Email'} value={me?.email} />
                <ProfileDetail label={'Name'} value={`${me?.first_name} ${me?.last_name}`} />
                <ProfileDetail label={'Phone'} value={`${me?.phone}`} />
                <ProfileDetail label={'Address'} value={me?.address1} />
                <ProfileDetail label={'Address 2'} value={me?.address2} />
                <ProfileDetail label={'City'} value={me?.city} />
                <ProfileDetail label={'State/Province'} value={me?.state} />
                <ProfileDetail label={'Country'} value={me?.country} />
                <ProfileDetail label={'Postal Code'} value={me?.postal_code} />
                <Divider />
                <Button
                  variant="text"
                  size="large"
                  onClick={() => history.push(ApplicationPaths.REQUEST_CHARITY)}
                  sx={{ width: '100%', color: '#000', textTransform: 'unset', paddingY: '18px' }}
                >
                  Request Charity
                </Button>
                <Divider />
                <Button
                  variant="text"
                  size="large"
                  onClick={() => {
                    history.push(ApplicationPaths.PROFILE_EDIT);
                  }}
                  sx={{ width: '100%', color: '#000', textTransform: 'unset', paddingY: '18px', marginBottom: '-24px' }}
                >
                  Update Profile
                </Button>
              </Box>
            </>
          }
        >
          <Container>
            <ButtonWithIcon
              onClick={() => {
                history.push(ApplicationPaths.LOGOUT);
              }}
              text={'Logout'}
              icon={<LogoutIcon style={{ fontSize: '30px' }} />}
            />
          </Container>
        </SplitLayout>
      </IonContent>
      <NavigationTabBar />
    </IonPage>
  );
};

export default ProfilePage;
