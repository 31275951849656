import React from 'react';
import { Payment } from '../../pages/ContributorDetailsPage';
import TransactionHistory from '../TransactionHistory';

type Props = {
  payments: Payment[];
};

const ContributorTransactionsTab = (props: Props): JSX.Element => {
  return (
    <>
      <TransactionHistory payments={props.payments} />
    </>
  );
};

export default ContributorTransactionsTab;
