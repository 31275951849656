import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { tabIndexAttributes } from '../../utils/tabs';

type Props = {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  currentTab: number;
};

const DashboardTabNav = ({ onChange, currentTab }: Props): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={onChange}
        variant="scrollable"
        scrollButtons={false}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        sx={{
          justifyContent: 'center',
          '& .MuiTab-root': {
            color: '#b2b2b2',
            fontSize: '14px',
            textTransform: 'none',
          },
          '& .Mui-selected': {
            fontWeight: 'bold',
            color: '#fff !important',
          },
          '& .MuiTabs-indicator': {
            height: '5px',
            backgroundColor: 'rgba(22, 179, 239, 1)',
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
          ['@media (max-width:480px)']: {
            '& .MuiTabs-flexContainer': {
              justifyContent: 'flex-start',
            },
          },
        }}
      >
        <Tab label="Travel Rewards" {...tabIndexAttributes(0)} />
        <Tab label="Funds Raised" {...tabIndexAttributes(1)} />
        <Tab label="Meals Provided" {...tabIndexAttributes(2)} />
      </Tabs>
    </Box>
  );
};

export default DashboardTabNav;
