import BeforeLoginLayout from '../layouts/BeforeLoginLayout';
import { IonContent } from '@ionic/react';
import LoginForm from '../components/LoginForm';
import LogoHeadingInfo from '../components/LogoHeadingInfo';
import React from 'react';

const LoginPage: React.FC = () => {
  return (
    <IonContent>
      <BeforeLoginLayout>
        <div style={{ fontFamily: 'Inter V_Bold' }}>
          <LogoHeadingInfo title="Sign In" text="Please sign in to proceed to your account." />
        </div>
        <LoginForm />
      </BeforeLoginLayout>
    </IonContent>
  );
};

export default LoginPage;
