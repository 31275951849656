import { IonContent, IonPage } from '@ionic/react';

import { Container } from '@mui/material';
import DonationHistory from '../components/ContributorDashboard/DonationHistory';
import Header from '../components/Header';
import NavigationTabBar from '../components/NavigationTabBar';
import React from 'react';

const ContributorHomePage: React.FC = () => {
  return (
    <IonPage>
      <Header title="Subscriber Home" logOut />
      <IonContent fullscreen>
        <Container style={{ padding: '40px 20px 40px 20px' }}>
          <DonationHistory />
        </Container>
      </IonContent>
      <NavigationTabBar />
    </IonPage>
  );
};

export default ContributorHomePage;
