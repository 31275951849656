import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface Props extends ButtonProps {
  text: string;
  icon: React.ReactNode;
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '20px',
  textTransform: 'none',
  '&.redeem_button': {
    backgroundColor: '#fff',
    color: '#000000',
    height: '67px',
    width: '100%',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .redeem_button__icon': {
    alignSelf: 'center',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '30px',
    backgroundColor: '#06719b',
    borderRadius: '50%',
    color: '#fff',
    padding: '7px',
    width: '44px',
    height: '44px',
  },
}));

const ButtonWithIcon = ({ text, icon, ...props }: Props): JSX.Element => {
  return (
    <CustomButton
      variant="contained"
      fullWidth
      className="redeem_button component-box-shadow"
      style={{ marginBottom: '14px' }}
      {...props}
    >
      <span>{text}</span>
      {icon}
    </CustomButton>
  );
};

export default ButtonWithIcon;
