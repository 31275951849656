import React, { useContext } from 'react';

import AppContext from '../context/appContext';
import ContributorHomePage from '../pages/ContributorHomePage';
import VolunteerDashboardPage from '../pages/VolunteerDashboardPage';
import { Box } from '@mui/material';

const Dashboard = (): JSX.Element => {
  const { hasRole, loading, me } = useContext(AppContext);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <img src="./assets/pdt_logo.svg" style={{ maxWidth: 480 }} />
        <p>Loading...</p>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {hasRole(['CONTRIBUTOR']) && <ContributorHomePage />}
      {hasRole(['ADMINISTRATOR', 'VOLUNTEER']) && <VolunteerDashboardPage />}
    </React.Fragment>
  );
};

export default Dashboard;
