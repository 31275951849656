import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  value?: string;
  component?: React.ReactNode;
};

const ProfileDetail = (props: Props): JSX.Element => {
  const conditionalMargin = props.component ? { marginLeft: '9px' } : {};

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
        <Typography sx={{ fontSize: '12.75px' }}>{props.label}</Typography>
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          {props.component && props.component}
          {!props.value && props.label !== 'Address 2' && <Skeleton height={12} width={60} animation={'pulse'} />}
          {props.value && (
            <Typography sx={{ fontSize: '12.75px', color: 'rgba(0,0,0,0.54)', ...conditionalMargin }}>
              {props.value}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
export default ProfileDetail;
