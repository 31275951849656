type ValidationErrors = {
    [key:string]: string[]
}

type FormattedError = {
    [key:string]: string
}

export const formatFormErrors = (validationErrors: ValidationErrors): FormattedError => {
    const formattedErrors:FormattedError = {}

    Object.keys(validationErrors).map((key)=>{
        formattedErrors[key] = validationErrors[key].join(', ');
      })

    return formattedErrors;
}