import { IonContent, IonPage } from '@ionic/react';
import { Box, Container } from '@mui/material';
import React from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import LogoHeadingInfo from '../components/LogoHeadingInfo';
import BeforeLoginLayout from '../layouts/BeforeLoginLayout';

const ForgotPasswordPage = () => {
  return (
    <>
      <IonContent>
        <BeforeLoginLayout>
          <div style={{ fontFamily: 'Inter V_Bold' }}>
            <LogoHeadingInfo title="Forgot Password" text="Reset password via email" />
          </div>
          <Container>
            <Box sx={{ display: 'flex' }} justifyContent={'center'}>
              <ForgotPasswordForm />
            </Box>
          </Container>
        </BeforeLoginLayout>
      </IonContent>
    </>
  );
};

export default ForgotPasswordPage;
