import { Box, Skeleton, Typography } from '@mui/material';

const ActivitySkeleton = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <Skeleton height={200} width={200} />
      </Box>
      <Box sx={{ flexGrow: 1, margin: '40px' }}>
        <Typography sx={{ color: '#06719b', fontSize: '20px', fontWeight: 'bold' }}>
          <Skeleton />
          <Typography component={'span'} sx={{ fontSize: '9px', color: 'rgba(0,0,0,0.5)' }}>
            <Skeleton />
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: '10px', color: 'rbga(0,0,0,0.73)' }}>
          <Skeleton />
        </Typography>
      </Box>
    </Box>
  );
};

export default ActivitySkeleton;
