import { IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import React from 'react';
import APIEndpoints from '../../utils/endpoints';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { IRegisterFormValues } from './VolunteerRegister';
import pdtClient from '../../lib/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
  formik: FormikProps<IRegisterFormValues>;
};

type CharityResponse = {
  data: Charity[];
};

type Charity = {
  name: string;
  id: number;
};

const CharitySelection = ({ formik }: Props): JSX.Element => {
  const [charities, setCharities] = useState<Charity[]>([]);

  useEffect(() => {
    async function getCharities() {
      try {
        const response: AxiosResponse<CharityResponse> = await pdtClient.get(APIEndpoints.Charities);
        const { data } = response.data;
        setCharities(data);
      } catch (e) {
        console.log('Error loading charities');
      }
    }
    getCharities();
  }, []);

  return (
    <>
      <Grid container maxWidth="sm" spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth focused variant="standard">
            <InputLabel> Select Charity </InputLabel>
            <Select
              id="charity"
              name="charity"
              value={formik.values.charity}
              placeholder="Select One"
              onChange={formik.handleChange}
            >
              <MenuItem key={'0'} value={`0`}>
                Request a charity
              </MenuItem>
              {charities.map(({ name, id }) => (
                <MenuItem key={id} value={`${id}`}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.charity && (
              <FormHelperText color="danger" className="ion-padding-start">
                <small>{formik.errors.charity}</small>
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CharitySelection;
