import ApplicationPaths from '../utils/paths';
import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

type Props = {
  title: string;
  text: string;
};

const LogoHeadingInfo = ({ title, text }: Props): JSX.Element => {
  const history = useHistory();

  const handleHomeClick = () => {
    history.push(ApplicationPaths.HOME);
  };
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center" className="ion-padding-horizontal">
        <Grid item>
          <Box sx={{ maxWidth: 240 }}>
            <img className="mt-8" src="./assets/pdt_logo.svg" onClick={handleHomeClick} />
          </Box>
        </Grid>
        <Grid item className="ion-text-center">
          <h1
            style={{
              fontSize: '28px',
            }}
          >
            {title}
          </h1>
          <p style={{ color: 'rgba(0,0,0,0.54)', fontSize: '17px', lineHeight: 1.52 }}>{text}</p>
        </Grid>
      </Grid>
    </>
  );
};

export default LogoHeadingInfo;
