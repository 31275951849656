import { IonItem, IonLabel, IonList } from '@ionic/react';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import pdtClient from '../../lib/api';
import APIEndpoints from '../../utils/endpoints';
import ContributorInfo from './ContributorInfo';
import { Typography } from '@mui/material';
import currency from 'currency.js';

type Donation = {
  amount: string;
  date: string;
};

const DonationHistory = (): JSX.Element => {
  const [donationHistory, setDonationHistory] = useState<Donation[]>([]);
  const [numberOfPayments, setNumberOfPayments] = useState(0);
  const [donatedAmount, setDonatedAmount] = useState('$0');

  const getDonationHistory = async () => {
    try {
      const response = await pdtClient.get(APIEndpoints.UserDonationHistory);
      setDonationHistory(response.data.data);
    } catch (error) {
      //TODO: do something with the error
    }
  };

  useEffect(() => {
    getDonationHistory();
  }, []);

  useEffect(() => {
    setNumberOfPayments(donationHistory.length);

    let amount = currency(0);
    donationHistory.forEach((donation) => {
      amount = amount.add(donation.amount);
    });

    setDonatedAmount(amount.format());
  }, [donationHistory]);

  return (
    <>
      <ContributorInfo totalPayments={numberOfPayments} donationAmount={donatedAmount} />
      <Typography color={'primary'} variant={'h5'} style={{ fontWeight: 'bold', margin: '40px 0 0 0' }}>
        Payment History
      </Typography>
      <IonList lines="full" mode="md">
        {donationHistory.map(({ amount, date }, index) => {
          return (
            <IonItem key={index}>
              <IonLabel>
                <p>
                  <strong>{format(new Date(date), 'LLLL do yyyy')}</strong>, Amount: {amount}{' '}
                </p>
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );
};

export default DonationHistory;
