import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react';
import React, { useContext } from 'react';
import { apps, exit, list, personAdd, personCircleOutline, peopleOutline } from 'ionicons/icons';

import AppContext from '../context/appContext';
import ApplicationPaths from '../utils/paths';

const NavigationTabBar: React.FC = () => {
  const { hasRole } = useContext(AppContext);

  return (
    <IonTabBar slot="bottom">
      {hasRole(['VOLUNTEER']) && (
        <IonTabButton tab="tab1" href={ApplicationPaths.HOME}>
          <IonIcon icon={apps} />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
      )}
      {hasRole(['VOLUNTEER']) && (
        <IonTabButton tab="tab3" href={ApplicationPaths.CONTRIBUTOR_LIST}>
          <IonIcon icon={peopleOutline} />
          <IonLabel>Subscribers</IonLabel>
        </IonTabButton>
      )}
      {hasRole(['VOLUNTEER']) && (
        <IonTabButton tab="tab2" href={ApplicationPaths.PROFILE}>
          <IonIcon icon={personCircleOutline} />
          <IonLabel>My Profile</IonLabel>
        </IonTabButton>
      )}
    </IonTabBar>
  );
};

export default NavigationTabBar;
