import { IonContent, IonPage } from '@ionic/react';
import { Box, Container, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import NavigationTabBar from '../components/NavigationTabBar';
import RequestCharityForm from '../components/VolunteerDashboard/RequestCharityForm';
import AppContext from '../context/appContext';
import SplitLayout from '../layouts/SplitLayout';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RequestCharityPage = () => {
  const { updateMe, me } = useContext(AppContext);

  useEffect(() => {
    if (me) {
      updateMe({ ...me, visited_request_page: true });
    }
  }, []);
  return (
    <>
      <IonPage>
        <IonContent>
          <SplitLayout
            header={
              <>
                <Container>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Request Charity
                    </Typography>
                  </Box>
                </Container>
              </>
            }
            component={
              <>
                <Box sx={{ m: 3 }}>
                  <Typography variant="h5">How it works</Typography>
                  <Typography>
                    <ul>
                      <li>
                        Please indicate the name, address, city/country, email and phone number of your Charity of
                        Choice
                      </li>
                      <li>
                        Please include any necessary additional information in the message box (i.e., chapter, specific
                        sector)
                      </li>
                      <li>
                        Click request, and a member of our team will process the request and reach out to your
                        registered email address to communicate status updates
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </>
            }
          >
            <>
              <RequestCharityForm />
            </>
          </SplitLayout>
        </IonContent>
        <NavigationTabBar />
      </IonPage>
    </>
  );
};

export default RequestCharityPage;
