import { Container, Typography, Box } from '@mui/material';
import React from 'react';
import StatusDot from '../StatusDot';

type Props = {
  totalContributors: number;
  activeContributors: number;
};

const ContributorListHeader = (props: Props): JSX.Element => {
  return (
    <Container>
      <Box>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{props.totalContributors} Subscribers</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StatusDot active={true} />{' '}
        <Typography sx={{ marginLeft: '9px', fontSize: '14px' }}>{props.activeContributors} Active</Typography>
      </Box>
    </Container>
  );
};

export default ContributorListHeader;
