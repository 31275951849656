import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, RouteComponentProps, useLocation } from 'react-router-dom';
import AppContext from '../context/appContext';
import { Redirect } from 'react-router';

type ProtectedRouteProps = {
  component: React.ComponentType<RouteComponentProps>;
  [x: string]: any;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...restOfProps }) => {
  const { isAuthenticated } = useContext(AppContext);
  const location = useLocation();
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={'/'} from={location.pathname} />
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
};

export default ProtectedRoute;
