import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import App from './App';
import { AppProvider } from './context/appContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { firebaseInit } from './lib/firebase';
import { pdtTheme } from './theme/theme';
import reportWebVitals from './reportWebVitals';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

export const firebaseApp = firebaseInit();

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={pdtTheme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
