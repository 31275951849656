import { Grid, Divider, Typography, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { IContributorUpdatePaymentMethodFormValues } from '../../pages/ContributorUpdatePaymentMethodPage';
import DatePicker from '@mui/lab/DatePicker';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { addMonths } from 'date-fns';

type Props = {
  formik: FormikProps<IContributorUpdatePaymentMethodFormValues>;
};

const UpdatePaymentMethod = ({ formik }: Props): JSX.Element => {
  const defaultDate = addMonths(new Date(), 1);
  const [value, setValue] = useState<Date | null>(defaultDate);

  useEffect(() => {
    if (!value) {
      formik.setFieldValue('cc_expiry', defaultDate);
      return;
    }
    formik.setFieldValue('cc_expiry', value?.toString());
  }, [value]);

  return (
    <>
      <Typography
        variant={'h2'}
        sx={{ fontSize: '24px', textAlign: 'center' }}
        gutterBottom={true}
        style={{ marginTop: 35 }}
      >
        Update payment method
      </Typography>

      <Divider sx={{ mt: 1, mb: 4 }} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputMask
            id="cc_number"
            name="cc_number"
            onChange={formik.handleChange}
            mask="9999 9999 9999 9999"
            value={formik.values.cc_number}
            disabled={false}
          >
            {() => (
              <TextField
                variant="filled"
                fullWidth
                id="cc_number"
                name="cc_number"
                autoComplete="cc-number"
                onChange={formik.handleChange}
                label="Card Number"
                helperText={formik.errors.cc_number}
                error={formik.touched.cc_number && Boolean(formik.errors.cc_number)}
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={4} md={6}>
          <TextField
            variant="filled"
            fullWidth
            id="cc_security_digits"
            name="cc_security_digits"
            value={formik.values.cc_security_digits}
            onChange={formik.handleChange}
            autoComplete="cc-csc"
            label="CSV"
            helperText={formik.errors.cc_security_digits}
            error={formik.touched.cc_security_digits && Boolean(formik.errors.cc_security_digits)}
            type="number"
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <DatePicker
            views={['year', 'month']}
            label="Expiry Year and Month"
            minDate={defaultDate}
            value={value}
            onChange={setValue}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                fullWidth
                helperText={formik.errors.cc_expiry}
                error={formik.touched.cc_expiry && Boolean(formik.errors.cc_expiry)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="filled"
            fullWidth
            id="cc_first_name"
            name="cc_first_name"
            value={formik.values.cc_first_name}
            onChange={formik.handleChange}
            label="Cardholder first name"
            helperText={formik.errors.cc_first_name}
            error={formik.touched.cc_first_name && Boolean(formik.errors.cc_first_name)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="filled"
            fullWidth
            id="cc_last_name"
            name="cc_last_name"
            value={formik.values.cc_last_name}
            onChange={formik.handleChange}
            label="Cardholder last name"
            helperText={formik.errors.cc_last_name}
            error={formik.touched.cc_last_name && Boolean(formik.errors.cc_last_name)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            id="postal_code"
            name="cc_postal_code"
            value={formik.values.cc_postal_code}
            onChange={formik.handleChange}
            label="Postal Code"
            helperText={formik.errors.cc_postal_code}
            error={formik.touched.cc_postal_code && Boolean(formik.errors.cc_postal_code)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UpdatePaymentMethod;
