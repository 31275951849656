import { Card, CardActionArea, CardContent, Chip, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import APIEndpoints from '../../utils/endpoints';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { IContributorRegisterFormValues } from './ContributorRegister';
import pdtClient from '../../lib/api';

type Props = {
  formik: FormikProps<IContributorRegisterFormValues>;
  updateAmount: (amount: string) => void;
};

type DonationPackage = {
  id: number;
  name: string;
  amount: string;
};

type PaymentPackagesResponse = {
  data: DonationPackage[];
};

const DonationPackages = ({ formik, updateAmount }: Props): JSX.Element => {
  const [donationPackages, setDonationPackages] = useState<DonationPackage[]>([]);

  async function getDonationPackages() {
    const response: AxiosResponse<PaymentPackagesResponse> = await pdtClient.get(APIEndpoints.PaymentPackages);
    const { data } = response.data;
    setDonationPackages(data);
  }

  useEffect(() => {
    getDonationPackages();
  }, []);

  const [paymentPackage, setPaymentPackage] = useState<null | number>(null);

  const handleClick = (paymentPackageId: number) => {
    setPaymentPackage(paymentPackageId);
    formik.setFieldValue('payment_package', paymentPackageId);
  };

  return (
    <>
      <Typography
        variant={'h2'}
        sx={{ fontSize: '24px', textAlign: 'center' }}
        gutterBottom={true}
        style={{ marginTop: 35 }}
      >
        Choose your program
      </Typography>
      <Grid
        container
        spacing={3}
        sx={{
          alignItems: 'stretch',
        }}
        justifyContent={'center'}
      >
        {donationPackages.map(({ name, id, amount }) => (
          <Grid item xs={12} md={6} key={id}>
            <Card sx={{ height: '100%', boxShadow: 4 }}>
              <CardActionArea
                onClick={() => {
                  handleClick(id);
                  updateAmount(amount);
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ fontWeight: 'bold' }}
                    component="div"
                    sx={{ fontSize: '17px' }}
                  >
                    Impact & Rewards Subscription Package - {amount}
                    {' USD'}
                    {paymentPackage === id && <Chip color="primary" sx={{ ml: 2 }} label={'Selected'} />}
                  </Typography>
                  <Divider />
                  <Typography sx={{ py: 2 }}>
                    <Typography sx={{ py: 2 }} component={'span'} style={{ fontWeight: 'bold' }}>
                      Impact:{' '}
                    </Typography>
                    Support your volunteer in raising money for their selected charity and giving one hungry child in
                    North America 30 healthy non-gmo, organic meals.
                  </Typography>
                  <Typography>
                    <Typography sx={{ py: 2 }} component={'span'} style={{ fontWeight: 'bold' }}>
                      Rewards:{' '}
                    </Typography>
                    Be automatically drawn into our Monthly Giveaways to win amazing prizes, along with exclusive access
                    to our discount app with hundreds of dollars worth of savings each month when shopping, dining out,
                    or traveling.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography sx={{ fontSize: '10px', color: 'error.main', textAlign: 'center', mt: 3 }}>
        {formik.errors.payment_package}
      </Typography>
    </>
  );
};

export default DonationPackages;
