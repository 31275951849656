import { Typography } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../../context/appContext';

const UserInfoCommon: React.FC = () => {
  const { userName } = useContext(AppContext);
  return (
    <>
      <Typography variant={'h4'} style={{ color: '#282828', fontWeight: 'bold' }}>
        Welcome, {userName}
      </Typography>
    </>
  );
};

export default UserInfoCommon;
