import { Box } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

type ActivityData = {
  name: string;
  value: number;
  color: string;
};

export interface ActivityChartProps {
  data: ActivityData[];
  info: React.ReactElement;
}

const ActivitiesChart = (props: ActivityChartProps): JSX.Element => {
  const { data, info } = props;

  return (
    <>
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }} component="div">
        <PieChart width={250} height={250}>
          <Pie data={data} cornerRadius={8} innerRadius={90} outerRadius={108} paddingAngle={5} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          {info}
        </Box>
      </Box>
    </>
  );
};

export default ActivitiesChart;
