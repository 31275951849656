import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { tabIndexAttributes } from '../../utils/tabs';
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';

type Props = {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  currentTab: number;
};

const ContributorDetailsTabNav = ({ onChange, currentTab }: Props): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={onChange}
        variant="scrollable"
        scrollButtons={false}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        sx={{
          '& .MuiTab-root': {
            color: 'rgba(0,0,0,0.5)',
            fontSize: '14px',
            textTransform: 'none',
            width: '50%',
            flexDirection: 'unset',
            alignItems: 'center',
            minHeight: 'unset',
          },
          '& .Mui-selected': {
            color: 'rgba(22,179,239,1) !important',
          },
          '& .MuiTabs-indicator': {
            height: '2px',
            backgroundColor: 'rgba(22,179,239,1)',
          },
          '& .MuiSvgIcon-root': {
            marginBottom: 'unset !important',
            marginRight: '6px',
          },
        }}
      >
        <Tab icon={<PersonIcon />} label="Profile" {...tabIndexAttributes(0)} />
        <Tab icon={<ListIcon />} label="Transactions" {...tabIndexAttributes(1)} />
      </Tabs>
    </Box>
  );
};

export default ContributorDetailsTabNav;
